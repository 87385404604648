@import 'src/app/shared/styles/mixins/notification-message';

.notifier__notification {
  display: flex;
  width: 403px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 17px 30px 19px 22px;
  box-shadow: -4px 4px 10px rgba(156, 174, 197, 0.15);
  border-radius: 3px;

  &:before {
    content: '';
    display: flex;
    width: 18px;
    min-width: 18px;
    height: 18px;

    margin-top: 5px;
    margin-right: 10px;
  }

  &.notifier__notification--success {
    background: #DFF2EE;

    &:before {
      background-image: url("~src/assets/images/icons/success-notification-icon.svg");
    }
  }

  &.notifier__notification--error, &.notifier__notification--blocked-status {
    background: #F3E7EA;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0ZM14.3571 10.0714H3.64286V7.92857H14.3571V10.0714Z' fill='%23E05679'/%3E%3C/svg%3E");
    }
  }

  &.notifier__notification--blocked-status {
    visibility: visible!important;
    display: flex!important;
  }

  .notifier__notification-message {
    @include notification-message();
  }

  .notifier__notification-button {
    display: none;
  }
}

@media (max-width: 500px) {
  .notifier__notification {
    max-width: calc(100% - 40px);
    right: 20px!important;
    padding: 20px 18px 20px 12px;
  }
}