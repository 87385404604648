.scroll-wrapper {
  resize: none;
  overflow: hidden;

  &.full-height {
    height: 100vh;
    height: 100dvh;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  .scrollbar {
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  &.vertical {
    &:not(.horizontal) {
      .global-scrollbar {
        overflow-x: hidden;
      }
    }
  }
}
