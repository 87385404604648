*, *::after, *::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  min-height: 100vh;
  font-family: "Proxima Nova";
  font-size: 14px;
  line-height: 17px;
  color: #293853;
  background: #FEFEFF;
  margin: 0;
  height: 100vh;
  height: 100dvh;
  min-height: -webkit-fill-available;
}

body, html {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer */
}

::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari, Edge) */
  width: 0;
  scrollbar-width: none;
  opacity: 0;
  background: transparent;
}

::-webkit-scrollbar-track {
  width: 0;
  scrollbar-width: none;
  scrollbar-gutter: unset;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  width: 0;
  scrollbar-width: none;
}

.hidden {
  display: none;
}

.fully-hidden {
  display: none !important;
  pointer-events: none;
  margin: 0;
  padding: 0;
}

p, ul {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a, a:hover {
  text-decoration: none;
}

a:focus, button:focus {
  outline: none;
}

.icon {
  fill: #AFB7C0;
}

.link {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.link[disabled] {
  color: #AFB7C0;
}
.link[disabled]:hover span {
  color: #AFB7C0;
}

.btn {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  transition: border-color 180ms ease-in-out, background 180ms ease-in-out, background-color 180ms ease-in-out;
}
.btn.disabled, .btn[disabled] {
  cursor: default;
  pointer-events: none;
}
.btn .btn__icon {
  transition: fill 180ms ease-in-out;
}
.btn .btn__text {
  flex-shrink: 0;
  transition: color 180ms ease-in-out;
}
@media (max-width: 1023px) {
  .btn, .btn .btn__icon, .btn .btn__text {
    transition: none;
  }
}

.btn--new-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  padding: 2px;
  background: transparent;
}
.btn--new-primary .btn__icon {
  fill: #9CAEC5;
  margin-right: 10px;
}
.btn--new-primary .btn__text {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #293853;
}
.btn--new-primary.small {
  height: 33px;
}
.btn--new-primary.small .btn__text {
  font-size: 14px;
  line-height: 17px;
}
.btn--new-primary.bright {
  border-color: #525ECC;
  background: #525ECC;
  border-radius: 3px;
}
.btn--new-primary.alert {
  border-color: #E05679;
  background: #E05679;
  border-radius: 3px;
}
.btn--new-primary.bright .btn__icon, .btn--new-primary.alert .btn__icon {
  fill: #FDFDFD;
}
.btn--new-primary.bright .btn__text, .btn--new-primary.alert .btn__text {
  color: #FDFDFD;
}
.btn--new-primary:not([disabled]):hover .btn__icon {
  fill: #293853;
}
.btn--new-primary:not([disabled]):hover.bright {
  border-color: #6570D1;
  background: #6570D1;
}
.btn--new-primary:not([disabled]):hover.alert {
  border-color: #EE7895;
  background: #EE7895;
}
.btn--new-primary[disabled] .btn__text, .btn--new-primary[disabled]:hover .btn__text {
  color: #DBDEE2;
}
.btn--new-primary[disabled] .btn__icon, .btn--new-primary[disabled]:hover .btn__icon {
  fill: #DBDEE2;
}
.btn--new-primary[disabled].disabled-background, .btn--new-primary[disabled]:hover.disabled-background {
  border-color: #FDFDFD;
  background: #FDFDFD;
}
.btn--new-primary[disabled].bright, .btn--new-primary[disabled].alert, .btn--new-primary[disabled]:hover.bright, .btn--new-primary[disabled]:hover.alert {
  border-color: #DBDEE2;
  background: #DBDEE2;
}
.btn--new-primary[disabled].bright .btn__text, .btn--new-primary[disabled].alert .btn__text, .btn--new-primary[disabled]:hover.bright .btn__text, .btn--new-primary[disabled]:hover.alert .btn__text {
  color: #FDFDFD;
}
.btn--new-primary[disabled].bright .btn__icon, .btn--new-primary[disabled].alert .btn__icon, .btn--new-primary[disabled]:hover.bright .btn__icon, .btn--new-primary[disabled]:hover.alert .btn__icon {
  fill: #FDFDFD;
}
.btn--new-primary.full-width {
  width: 100%;
}

.btn--icon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.btn--icon .btn__icon {
  fill: #9CAEC5;
}
.btn--icon .btn__text {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9CAEC5;
  margin-left: 5px;
}
.btn--icon.large .btn__text {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  top: 1px;
  margin-left: 10px;
}
.btn--icon.bright .btn__text {
  color: #293853;
}
.btn--icon:not(.disabled):hover .btn__icon {
  fill: #293853;
}
.btn--icon:not(.disabled):hover .btn__text {
  color: #293853;
}
.btn--icon.disabled {
  cursor: default;
}
.btn--icon.disabled .btn__icon {
  fill: #DBDEE2;
}
.btn--icon.disabled .btn__text {
  color: #DBDEE2;
}

.btn--action {
  display: inline-flex;
  align-items: center;
}
.btn--action .btn__icon {
  fill: #9CAEC5;
  margin-left: 3px;
}
.btn--action .btn__text {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #9CAEC5;
}
.btn--action.bright .btn__text {
  color: #5360D9;
}
.btn--action:not([disabled]):hover .btn__icon {
  fill: #293853;
}
.btn--action:not([disabled]):hover .btn__text {
  color: #293853;
}
.btn--action[disabled] .btn__icon {
  fill: #DBDEE2;
}
.btn--action[disabled] .btn__text {
  color: #DBDEE2;
}

.btn__base {
  color: #9CAEC5;
}
.btn__base.second-base {
  color: #A8ADB4;
}
.btn__base:hover {
  color: #293853;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

textarea {
  display: block;
  width: 100%;
  resize: none;
}

input:focus, textarea:focus {
  outline: none;
}

.field {
  position: relative;
}
.field.password {
  padding-right: 38px !important;
}
.field .field__eye {
  display: none;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  right: 18px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8168 1.68241C14.6668 1.53243 14.4633 1.44818 14.2512 1.44818C14.0391 1.44818 13.8356 1.53243 13.6856 1.68241L1.184 14.1856C1.10759 14.2594 1.04665 14.3477 1.00472 14.4453C0.962792 14.5429 0.940723 14.6479 0.9398 14.7541C0.938877 14.8603 0.959118 14.9657 0.999343 15.064C1.03957 15.1623 1.09897 15.2516 1.17408 15.3267C1.2492 15.4018 1.33852 15.4612 1.43684 15.5015C1.53515 15.5417 1.6405 15.5619 1.74672 15.561C1.85294 15.5601 1.95792 15.538 2.05552 15.4961C2.15313 15.4542 2.2414 15.3932 2.3152 15.3168L14.816 2.81361C14.966 2.66359 15.0502 2.46014 15.0502 2.24801C15.0502 2.03588 14.9668 1.83243 14.8168 1.68241ZM2.4864 11.2984L4.5344 9.25041C4.476 9.0045 4.44568 8.75276 4.444 8.50001C4.444 6.59681 6.036 5.05281 8 5.05281C8.2288 5.05281 8.4512 5.07841 8.668 5.11841L9.6304 4.15681C9.0915 4.06753 8.54625 4.02205 8 4.02081C2.752 4.02001 0 7.88481 0 8.50001C0 8.83841 0.8456 10.1728 2.4864 11.2984ZM13.516 5.70401L11.4664 7.75201C11.5216 7.99361 11.5552 8.24241 11.5552 8.50001C11.5552 10.4032 9.964 11.9456 8 11.9456C7.7728 11.9456 7.552 11.92 7.3368 11.8808L6.3736 12.8432C6.8872 12.9264 7.4264 12.9792 8 12.9792C13.248 12.9792 16 9.11281 16 8.50001C16 8.16081 15.1552 6.82801 13.516 5.70401Z' fill='%239CAEC5'/%3E%3C/svg%3E%0A");
}
.field .field__eye.show {
  display: block;
}
.field .field__eye.active {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 4.02002C2.7512 4.02002 0 7.88562 0 8.50002C0 9.11282 2.7512 12.98 8 12.98C13.248 12.98 16 9.11282 16 8.50002C16 7.88562 13.248 4.02002 8 4.02002ZM8 11.9456C6.036 11.9456 4.444 10.4032 4.444 8.50002C4.444 6.59682 6.036 5.05282 8 5.05282C9.964 5.05282 11.5552 6.59682 11.5552 8.50002C11.5552 10.4032 9.964 11.9456 8 11.9456ZM8 8.50002C7.6744 8.14242 8.5304 6.77682 8 6.77682C7.0176 6.77682 6.2216 7.54882 6.2216 8.50002C6.2216 9.45122 7.0176 10.2232 8 10.2232C8.9816 10.2232 9.7784 9.45122 9.7784 8.50002C9.7784 8.06242 8.2768 8.80322 8 8.50002Z' fill='%239CAEC5'/%3E%3C/svg%3E%0A");
}
.field .field__input {
  width: 100%;
  padding: 9px 24px;
  font-size: 16px;
  line-height: 32px;
  border: 1px solid #DBE3EE;
  border-radius: 1px;
  transition: border 180ms ease-in-out;
  height: 52px;
}
.field .field__input.field__input--sm {
  font-size: 14px;
  line-height: 24px;
  padding: 5px 18px;
}
.field .field__input:hover, .field .field__input:focus {
  border-color: #757986;
}
.field .field__input::placeholder {
  color: #AFB7C0;
  opacity: 1;
}
.field .field__input.textarea {
  padding: 20px 32px;
}
.field .field__input.textarea.normal {
  padding: 10px 28px;
}
.field + .field {
  margin-top: 24px;
}

@media (max-width: 1359px) {
  .form .field__input {
    font-size: 15px;
    line-height: 32px;
    padding-left: 22px;
    padding-right: 22px;
  }
  .form .field__input.textarea {
    padding: 20px 32px;
  }
  .form .field__input.textarea.normal {
    padding: 10px 28px;
  }
  .form + .field {
    margin-top: 20px;
  }
}
@media (max-width: 575px) {
  .form .field__input {
    font-size: 14px;
    height: 42px;
    padding-left: 28px;
    padding-right: 28px;
  }
}
h1 {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

h2 {
  font-family: "Proxima Nova Medium";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

h4 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

h5 {
  font-family: "Proxima Nova Bold Italic";
  font-style: italic;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}

h6 {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.h7 {
  line-height: 23px;
}

.clickable {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.hide-opacity {
  cursor: default;
  pointer-events: none;
  user-select: none;
  opacity: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.full-width {
  width: 100%;
}

.flex-full-width {
  display: flex;
  width: 100%;
}

.text-with-bold-span span {
  font-family: "Proxima Nova Bold";
}

.text-with-bold-highlight-span span {
  font-family: "Proxima Nova Bold Italic";
}

.pre-line {
  white-space: pre-line;
}

.bold {
  font-family: "Proxima Nova Bold";
}

.preview-block {
  display: flex;
  background: #DBE3EE;
  border-radius: 4px;
}

.with-inner-facet {
  word-break: break-word;
}
.with-inner-facet .facet {
  margin-left: 10px;
}

ul.list {
  list-style: none;
  padding: 0;
}
ul.list li.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}
ul.list li.item.active {
  cursor: default;
}
ul.list.with-background {
  background: #FDFDFD;
}
ul.list.with-background li.item__text {
  color: #293853;
}
ul.list.with-background li.item.active .item__text {
  color: #606DE4;
}
ul.list.with-background li.item:hover {
  background: #F3F5F8;
}
ul.list.dropdown-list {
  border: 1px solid #E1E7EE;
  border-radius: 3px;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  height: fit-content;
}
ul.list.dropdown-list .item {
  padding: 5px 11px;
}
ul.list.dropdown-list .item__text {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
ul.list.dropdown-list .item.active .item__text {
  margin-right: 8px;
}
ul.list.dropdown-list .item.active:after {
  content: "";
  display: flex;
  background-image: url("~src/assets/images/icons/done.svg");
  width: 14px;
  height: 14px;
}
ul.list.dropdown-list .item + .item {
  margin-top: 5px;
}
ul.list.dropdown-list.small {
  padding: 7px 0;
}
ul.list.dropdown-list.small .item {
  padding: 8px 19px;
}
ul.list.dropdown-list.small .item + .item {
  margin-top: 8px;
}
ul.list.dropdown-list.small .item__text {
  font-size: 14px;
  line-height: 17px;
}
ul.list.dropdown-list.small.active .item__text {
  margin-right: 10px;
}

@media (max-width: 1023px) {
  .system-global-page {
    opacity: 0;
  }
  .only-desktop {
    display: none !important;
  }
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.mat-slider-horizontal {
  min-width: 100% !important;
}

.notifier__container * {
  box-sizing: border-box;
}
.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.notifier__notification {
  display: flex;
  align-items: center;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  backface-visibility: hidden;
}

.notifier__notification {
  display: flex;
  width: 403px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 17px 30px 19px 22px;
  box-shadow: -4px 4px 10px rgba(156, 174, 197, 0.15);
  border-radius: 3px;
}
.notifier__notification:before {
  content: "";
  display: flex;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-top: 5px;
  margin-right: 10px;
}
.notifier__notification.notifier__notification--success {
  background: #DFF2EE;
}
.notifier__notification.notifier__notification--success:before {
  background-image: url("~src/assets/images/icons/success-notification-icon.svg");
}
.notifier__notification.notifier__notification--error, .notifier__notification.notifier__notification--blocked-status {
  background: #F3E7EA;
}
.notifier__notification.notifier__notification--error:before, .notifier__notification.notifier__notification--blocked-status:before {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0ZM14.3571 10.0714H3.64286V7.92857H14.3571V10.0714Z' fill='%23E05679'/%3E%3C/svg%3E");
}
.notifier__notification.notifier__notification--blocked-status {
  visibility: visible !important;
  display: flex !important;
}
.notifier__notification .notifier__notification-message {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #293853;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.notifier__notification .notifier__notification-button {
  display: none;
}

@media (max-width: 500px) {
  .notifier__notification {
    max-width: calc(100% - 40px);
    right: 20px !important;
    padding: 20px 18px 20px 12px;
  }
}
.scroll-wrapper {
  resize: none;
  overflow: hidden;
}
.scroll-wrapper.full-height {
  height: 100vh;
  height: 100dvh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.scroll-wrapper .scrollbar {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}
.scroll-wrapper.vertical:not(.horizontal) .global-scrollbar {
  overflow-x: hidden;
}