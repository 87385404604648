fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

textarea {
  display: block;
  width: 100%;
  resize: none;
}

input:focus, textarea:focus {
  outline: none;
}

.field {
  position: relative;

  &.password {
    padding-right: 38px !important;
  }

  .field__eye {
    display: none;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    right: 18px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8168 1.68241C14.6668 1.53243 14.4633 1.44818 14.2512 1.44818C14.0391 1.44818 13.8356 1.53243 13.6856 1.68241L1.184 14.1856C1.10759 14.2594 1.04665 14.3477 1.00472 14.4453C0.962792 14.5429 0.940723 14.6479 0.9398 14.7541C0.938877 14.8603 0.959118 14.9657 0.999343 15.064C1.03957 15.1623 1.09897 15.2516 1.17408 15.3267C1.2492 15.4018 1.33852 15.4612 1.43684 15.5015C1.53515 15.5417 1.6405 15.5619 1.74672 15.561C1.85294 15.5601 1.95792 15.538 2.05552 15.4961C2.15313 15.4542 2.2414 15.3932 2.3152 15.3168L14.816 2.81361C14.966 2.66359 15.0502 2.46014 15.0502 2.24801C15.0502 2.03588 14.9668 1.83243 14.8168 1.68241ZM2.4864 11.2984L4.5344 9.25041C4.476 9.0045 4.44568 8.75276 4.444 8.50001C4.444 6.59681 6.036 5.05281 8 5.05281C8.2288 5.05281 8.4512 5.07841 8.668 5.11841L9.6304 4.15681C9.0915 4.06753 8.54625 4.02205 8 4.02081C2.752 4.02001 0 7.88481 0 8.50001C0 8.83841 0.8456 10.1728 2.4864 11.2984ZM13.516 5.70401L11.4664 7.75201C11.5216 7.99361 11.5552 8.24241 11.5552 8.50001C11.5552 10.4032 9.964 11.9456 8 11.9456C7.7728 11.9456 7.552 11.92 7.3368 11.8808L6.3736 12.8432C6.8872 12.9264 7.4264 12.9792 8 12.9792C13.248 12.9792 16 9.11281 16 8.50001C16 8.16081 15.1552 6.82801 13.516 5.70401Z' fill='%239CAEC5'/%3E%3C/svg%3E%0A");
    &.show {
      display: block;
    }
    &.active {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 4.02002C2.7512 4.02002 0 7.88562 0 8.50002C0 9.11282 2.7512 12.98 8 12.98C13.248 12.98 16 9.11282 16 8.50002C16 7.88562 13.248 4.02002 8 4.02002ZM8 11.9456C6.036 11.9456 4.444 10.4032 4.444 8.50002C4.444 6.59682 6.036 5.05282 8 5.05282C9.964 5.05282 11.5552 6.59682 11.5552 8.50002C11.5552 10.4032 9.964 11.9456 8 11.9456ZM8 8.50002C7.6744 8.14242 8.5304 6.77682 8 6.77682C7.0176 6.77682 6.2216 7.54882 6.2216 8.50002C6.2216 9.45122 7.0176 10.2232 8 10.2232C8.9816 10.2232 9.7784 9.45122 9.7784 8.50002C9.7784 8.06242 8.2768 8.80322 8 8.50002Z' fill='%239CAEC5'/%3E%3C/svg%3E%0A");
    }
  }

  .field__input {
    width: 100%;
    padding: 9px 24px;
    font-size: $regular-text;
    line-height: 32px;
    border: 1px solid $border;
    border-radius: 1px;
    transition: border $time-fast $easing-base;
    height: 52px;

    &.field__input--sm {
      font-size: $small-text;
      line-height: $small-line-height;
      padding: 5px 18px;
    }

    &:hover,
    &:focus{
      border-color: $helper;
    }

    &::placeholder{
      color: $neutral;
      opacity: 1;
    }

    &.textarea {
      padding: 20px 32px;

      &.normal {
        padding: 10px 28px;
      }
    }
  }

  & + .field {
    margin-top: 24px;
  }
}

@media (max-width: $laptop-large) {
  .form {
    .field__input {
      font-size: 15px;
      line-height: 32px;
      padding-left: 22px;
      padding-right: 22px;

      &.textarea {
        padding: 20px 32px;

        &.normal {
          padding: 10px 28px;
        }
      }
    }

    & + .field {
      margin-top: 20px;
    }
  }
}

@media (max-width: $mobile) {
  .form {
    .field__input {
      font-size: 14px;
      height: 42px;
      padding-left: 28px;
      padding-right: 28px;
    }
  }
}
