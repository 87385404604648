@import 'src/app/shared/styles/settings/type';
@import 'src/app/shared/styles/settings/colors';
@import 'src/app/shared/styles/settings/sizes';

@mixin notification-message {
  font-family: $base-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: $main;

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
