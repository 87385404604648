a, a:hover {
  text-decoration: none;
}

a:focus, button:focus{
  outline: none;
}

.icon {
  fill: $neutral;
}

//- links
.link {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &[disabled] {
    color: $neutral;

    &:hover span {
      color: $neutral;
    }
  }
}

// - buttons
.btn {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  transition: border-color $time-fast $easing-base, background $time-fast $easing-base, background-color $time-fast $easing-base;
  &.disabled, &[disabled] {
    cursor: default;
    pointer-events: none;
  }
  .btn__icon {
    transition: fill $time-fast $easing-base;
  }
  .btn__text {
    flex-shrink: 0;
    transition: color $time-fast $easing-base;
  }
  @media (max-width: 1023px) {
    &, .btn__icon, .btn__text {
      transition: none;
    }
  }
}

.btn--new-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 43px;
  padding: 2px;
  background: transparent;

  .btn__icon {
    fill: $gray;

    margin-right: 10px;
  }

  .btn__text {
    font-family: $bold-base-font;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $main;
  }

  &.small {
    height: 33px;

    .btn__text {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &.bright {
    border-color: #525ECC;
    background: #525ECC;
    border-radius: 3px;
  }

  &.alert {
    border-color: $not2;
    background: $not2;
    border-radius: 3px;
  }

  &.bright, &.alert {
    .btn__icon {
      fill: $white;
    }

    .btn__text {
      color: $white;
    }
  }

  &:not([disabled]):hover {
    .btn__icon {
      fill: $main;
    }

    &.bright {
      border-color: #6570D1;
      background: #6570D1;
    }

    &.alert {
      border-color: #EE7895;
      background: #EE7895;
    }
  }

  &[disabled], &[disabled]:hover {
    .btn__text {
      color: $gray4;
    }

    .btn__icon {
      fill: $gray4;
    }

    &.disabled-background {
      border-color: $white;
      background: $white;
    }

    &.bright, &.alert {
      border-color: $gray4;
      background: $gray4;

      .btn__text {
        color: $white;
      }

      .btn__icon {
        fill: $white;
      }

    }
  }

  &.full-width {
    width: 100%;
  }
}

.btn--icon {
  // icon - main - in left side, text - not required
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .btn__icon {
    fill: $gray;
  }

  .btn__text {
    font-family: $bold-base-font;
    font-style: normal;
    font-weight: normal;;
    font-size: 14px;
    line-height: 17px;
    color: $gray;

    margin-left: 5px;
  }

  &.large {
    .btn__text {
      position: relative;
      font-size: 16px;
      line-height: 19px;

      top: 1px;
      margin-left: 10px;
    }
  }

  &.bright {
    .btn__text {
      color: $main;
    }
  }

  &:not(.disabled):hover {
    .btn__icon {
      fill: $main;
    }

    .btn__text {
      color: $main;
    }
  }

  &.disabled {
    cursor: default;

    .btn__icon {
      fill: $gray4;
    }

    .btn__text {
      color: $gray4;
    }
  }
}

.btn--action {
  // text - main, icon in right side
  display: inline-flex;
  align-items: center;

  .btn__icon {
    fill: $gray;

    margin-left: 3px;
  }

  .btn__text {
    font-family: $bold-base-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $gray;
  }

  &.bright {
    .btn__text {
      color: $link;
    }
  }

  &:not([disabled]):hover {
    .btn__icon {
      fill: $main;
    }

    .btn__text {
      color: $main;
    }
  }

  &[disabled] {
    .btn__icon {
      fill: $gray4;
    }

    .btn__text {
      color: $gray4;
    }
  }
}

.btn__base {
  color: $gray;
  &.second-base {
    color: $gray2;
  }
  &:hover {
    color: $main;
  }
}

